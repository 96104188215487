window.jQuery = window.$ = require('jquery');

console.log('FORM HANDLER VERSION', 'CHARLIE');

(function ($) {
	$.fn.serializeFormJSON = function () {

			var o = {};
			var a = this.serializeArray();
			$.each(a, function () {
					if (o[this.name]) {
							if (!o[this.name].push) {
									o[this.name] = [o[this.name]];
							}
							o[this.name].push(this.value || '');
					} else {
							o[this.name] = this.value || '';
					}
			});
			return o;
	};
})($);

function getUrlParameter(sParam) {
    var sPageURL = window.location.search.substring(1);
    var sURLVariables = sPageURL.split('&');
    for (var i = 0; i < sURLVariables.length; i++) {
        var sParameterName = sURLVariables[i].split('=');
        if (sParameterName[0] == sParam) {
            return sParameterName[1];
        }
    }
}

$('.handler').submit(function(event) {
	event.preventDefault();
	var buttonText = $('.submit-button', this).html();
	$('.submit-button', this).prop("disabled", true).html("<i class='fa fa-spinner fa-spin'></i> Saving...");
	console.log('begin submission');
	var url = $(this).attr('action');
	var data = $(this).serializeFormJSON();
	var f = $(this);
	var successEl = $('input[name="onsuccess"]', this).val();
	$('.utm_source').val(getUrlParameter('utm_source'));
	$('.utm_campaign').val(getUrlParameter('utm_campaign'));
	$('.utm_medium').val(getUrlParameter('utm_medium'));
	$('.utm_term').val(getUrlParameter('utm_term'));
	$('.utm_content').val(getUrlParameter('utm_content'));
	console.log(JSON.stringify(data));
	$.ajax({
		type: "POST",
		url: url,
		data: JSON.stringify(data),
		headers: {
			'Accept': 'application/json',
			'Content-Type': 'application/json'
		},
		dataType: 'json',
		success: function(data) {
			console.log('SUCCESS' + JSON.stringify(data));
			if (typeof successEl !== 'undefined' && successEl !== '') {
				$('.submit-button').prop("disabled", true).html("Saved!");
				console.log('redirect');
				var redirect = successEl;
				setTimeout( function() {
					console.log('redirecting...' + redirect);
					window.location = redirect;
				}, 3000)
				
			} else {
				console.log('fade in/out');
				$('.submit-button').prop("disabled", true).html("Saved!");
				/*
				$('.show-form', f).fadeOut(400, function() {
					$('.complete', f).removeClass('hidden').fadeIn(400);
				});
				*/
			}
		},
		error: function() {
			console.log('fail');
			$('.submit-button', f).prop("disabled", false).html(buttonText);
		}
	});
});

'use strict';
// ES6 Supported
import $ from 'jquery';
import bootstrap from 'bootstrap';

import './form-handler';


/*
var didScroll;
var lastScrollTop = 0;
var delta = 10;
var navbarHeight = $('header nav.navbar').outerHeight();
var scrollPadding = 250; // use this to prevent the navbar from disappearing above a certain point

$(window).scroll(function(event){
  didScroll = true;
});

$(window).on('scroll', function(event) {
  console.log("scroll scroll scroll!")
  didScroll=true;
})

setInterval(function() {
  if (didScroll) {
      hasScrolled();
      didScroll = false;
  }
}, 250);

function hasScrolled() {
  var scrollTop = $(this).scrollTop();

  // Make sure they scroll more than delta
  if (Math.abs(lastScrollTop - scrollTop) <= delta) {
      return;
  }

  if (scrollTop > 10) {
    $('header nav.navbar').addClass('navbar-expanded');
  } else {
    $('header nav.navbar').removeClass('navbar-expanded');
  }
}
*/

console.log('JS Loaded!');